import React from "react"
import ImageRenderer from "../components/atoms/ImageRenderer"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserAlt, faUserTie } from "@fortawesome/free-solid-svg-icons"

const UserAuth = () => {
  const location = useLocation() // Get the current location
  const searchParams = new URLSearchParams(location.search) // Parse query parameters
  const authType = searchParams.get("auth-type")
  return (
    <div className="bg-[#FAF9F6] min-w-full min-h-[100vh]">
      <div className="mx-auto max-w-8xl px-4 pt-6 sm:px-6 lg:px-10">
        <nav
          className="mx-auto flex max-w-8xl items-center justify-between p-6 pb-3 lg:px-10"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <div>
              <Link className="relative z-[10]" to="/">
                <ImageRenderer
                  img={`synkli-logo-transparent`}
                  alt={"synkli-logo-transparent"}
                />
              </Link>
            </div>
          </div>
        </nav>
        <div className="isolate w-full h-full">
          <div className="relative w-full h-full">
            <div>
              <div className="flex min-h-full flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div>
                  <div className="h-full ">
                    <div className="relative" style={{ opacity: `1` }}>
                      <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[740px] bg-white rounded-xl sm:rounded-2xl px-2 pt-12 pb-5 sm:px-12 rounded-[18px] border-b-[20px] border-[#101828]">
                        <h3 className="text-2xl md:text-3xl lg:text-4xl font-[600] text-[#101828] text-center">
                          {authType === "login" || authType === "Login"
                            ? "Login"
                            : "Signup"}
                        </h3>
                        <div className="px-0 md:px-5">
                          <div className="block text-sm md:text-base font-normal leading-normal text-[#979797] mt-10 mb-5">
                            Please select your preferred{" "}
                            {authType === "login" || authType === "Login"
                              ? "login"
                              : "signup"}{" "}
                            method.
                          </div>
                          <div className="flex flex-col gap-3 mb-10">
                            <Link
                              to={
                                authType === "login" || authType === "Login"
                                  ? "https://partners.synkli.com.au/"
                                  : "https://partners.synkli.com.au/signup"
                              }
                              className="group relative flex flex-col cursor-pointer border border-gray-400 rounded-xl sm:px-4 px-2 py-2 focus:outline-none"
                            >
                              <div className="sm:flex block w-full items-center gap-2 p-2">
                                <FontAwesomeIcon
                                  icon={faUserTie}
                                  className="text-[35px] md:text-[60px] text-primary"
                                />
                                <span className="md:ml-3 mt-3 md:mt-0 flex-1 flex flex-col gap-1">
                                  <h5 className="text-lg font-medium text-[#101828] leading-1 block">
                                    Accountant{" "}
                                    {authType === "login" ||
                                    authType === "Login"
                                      ? "Login"
                                      : "Signup"}
                                  </h5>
                                  <div className="block text-sm md:text-base font-normal leading-normal text-[#666666]">
                                    {authType === "login" ||
                                    authType === "Login"
                                      ? "Login"
                                      : "Signup"}{" "}
                                    as an accountant and take practice management to the next level.
                                  </div>
                                </span>
                              </div>
                            </Link>

                            <Link
                              to={
                                authType === "login" || authType === "Login"
                                  ? "https://app.synkli.com.au/login"
                                  : "https://app.synkli.com.au/sign-up"
                              }
                              className="group relative flex flex-col cursor-pointer border border-gray-400 rounded-xl sm:px-4 px-2 py-2 focus:outline-none"
                            >
                              <div className="sm:flex block w-full items-center gap-2 p-2">
                                <FontAwesomeIcon
                                  icon={faUserAlt}
                                  className="text-[35px] md:text-[60px] text-primary"
                                />
                                <span className="md:ml-3 mt-3 md:mt-0flex-1 flex flex-col gap-1">
                                  <h5 className="text-lg font-medium text-[#101828] leading-1 block">
                                    Client{" "}
                                    {authType === "login" ||
                                    authType === "Login"
                                      ? "Login"
                                      : "Signup"}
                                  </h5>
                                  <div className="text-sm md:text-base font-normal leading-normal block text-[#666666]">
                                    {authType === "login" ||
                                    authType === "Login"
                                      ? "Login"
                                      : "Signup"}{" "}
                                    as an client and unlock a world of management opportunities.
                                  </div>
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserAuth
